import { collapseStickRightColumn } from '../bidding/common/table/CollapseStickRightColumn';
import { ColumnBuilder } from '../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../bidding/common/table/types/ColumnDefinition';
import { TickerButton } from '../common';
import { SellerBuysideSearchResult } from '../../types/bid-as-dealer/SellerBuysideSearchResult';
import { PxTalkListButton } from '../common/PxTalk/PxTalkListButton';
import { SellerBuySideBid } from './SellerBuysideBid';
import { SellerBuySideSubmitButton } from './SellerBuysideSubmitButton';
import { formatUtils, dateTimeUtils, moneyUtils } from '../../utils';
import { SellerBuySideRulesButton } from './SellerBuySideRulesButton';
import { BidFeedback, FeedbackCurrentBid } from '../bidding/brokerDealer/biddingSecurities/BidFeedback';
import { BwicProcessType } from '../../types/models/Process';
import { constants } from '../../constants';
import { SellerBuysideHistoryButton } from './SellerBuysideHistoryButton'
import { InfoTooltip } from "../common/InfoTooltip";
import { SellerBuysideBiddingInfo } from './SellerBuysideBiddingInfo';
import { SellerBuysideBwicStatusTimerLabel } from './SellerBuysideBwicStatusTimerLabel';
import { TradeAction } from '../trade/TradeAction';
import { LiveBiddingStage2Level } from '../bidding/common/feedback/LiveBiddingStage2Level';
import { BwicStatus } from '../../types/enums/BwicStatus';
import { IntexLink } from '../common/IntexLink';
import { BloombergLink } from '../common/BloombergLink';
import { ValitanaLink } from '../common/ValitanaLink';
import { AxedIcon } from "../bidding/common/AxedIcon";
import { FinalIcon } from "../bidding/common/FinalIcon";

type TContext = {
    liveBiddingStage2SubmissionDeadline?: Date;
    liveBiddingStage2Expired: boolean;
};

export function sellerBuySideColumns() {
    const columnDefinitions: IColumnDefinition<SellerBuysideSearchResult, TContext>[] = [
        {
            columnKey: 'bwic-status',
            renderColumnHeaderContent: () => '',
            renderColumnContent: entity => <SellerBuysideBwicStatusTimerLabel entity={entity} />,
            headerClassName: '',
            bodyClassName: '',
        }, {
            columnKey: 'start-date',
            renderColumnHeaderContent: () => (
                <>
                    BWIC Start Time
                    <InfoTooltip text="The time of BWIC start" />
                </>
            ),
            renderColumnContent: entity => dateTimeUtils.utcToLocalString(entity.bwic.bidsDueDateUtc, 'hh:mm A'),
            className: 'data-list-cell-md',
        }, {
            columnKey: 'bidding-info',
            renderColumnHeaderContent: () => 'Bidding Info',
            renderColumnContent: (entity, context) =>
                <SellerBuysideBiddingInfo
                    isColorDistribution={entity.bwic.isColorDistribution}
                    process={entity.bwic.process}
                    bwicStatus={entity.bwic.status}
                    canBidOnStage2={entity.position.isStage2Participant}
                    currentBid={entity.bidAsDealerRequest?.directBid}
                    expired={context.liveBiddingStage2Expired}
                />,
            className: 'data-list-cell-lg-02',
        }, {
            columnKey: 'rules',
            renderColumnHeaderContent: () => 'Rules',
            renderColumnContent: entity => (
                <SellerBuySideRulesButton
                    parsedProcess={entity.bwic.parsedProcess}
                    process={entity.bwic.process}
                    isParsed={entity.bwic.isParsed}
                    positionId={entity.position.id}
                    showLabel={false}
                />
            ),
            className: 'data-list-cell-sm',
        }, {
            columnKey: 'ticker',
            renderColumnHeaderContent: () => 'Ticker',
            renderColumnContent: entity => <TickerButton security={entity.position} fetchAgencyRatings={true} />,
            className: 'data-list-cell-lg',
        }, {
            columnKey: 'rating',
            renderColumnHeaderContent: () => 'Rtg',
            renderColumnContent: entity => entity.position.rating,
            className: 'data-list-cell-xs',
        }, {
            columnKey: 'size',
            renderColumnHeaderContent: () => 'Size, MM',
            renderColumnContent: entity => moneyUtils.amountToMM(entity.position.size),
            className: 'data-list-cell-xs text-right',
        }, {
            columnKey: 'px-talk',
            renderColumnHeaderContent: () => 'Px Talk',
            renderColumnContent: entity => (
                <PxTalkListButton
                    className="pseudo-link"
                    positionId={entity.position.id}
                    pxTalks={entity.position.pxTalks}
                />
            ),
            headerClassName: 'text-right',
            bodyClassName: 'text-right',
        }, {
            columnKey: 'my-bid',
            renderColumnHeaderContent: () => (
                <div className="seller-buy-side-bid justify-content-end">
                    My Bid
                    <div className="commision">Comm, %</div>
                    <div className="axed-final-icons">
                        <AxedIcon />
                        <FinalIcon />
                    </div>
                </div>
            ),
            renderColumnContent: (entity, context) =>
                <SellerBuySideBid
                    entity={entity}
                    disabled={context.liveBiddingStage2Expired}
                />,
            className: 'data-list-cell-xxl cell-bid-value text-right'
        }, {
            columnKey: 'feedback',
            renderColumnHeaderContent: () => 'Feedback',
            renderColumnContent: entity =>
                entity.bwic.process.type === BwicProcessType.Live
                    ? <div className="live-bidding-feedback">
                        <LiveBiddingStage2Level
                            stagedBiddingStatus={entity.bwic.process.stagedBiddingStatus}
                            levelSpecificationType={entity.bwic.process.liveBidding!.levelSpecificationType}
                            currentBid={entity.bidAsDealerRequest?.directBid}
                            stage2Level={entity.position.openBiddingStage2Level}
                            tiedToBest={entity.position.tiedForBest}
                            animated={entity.bwic.status === BwicStatus.bidding}
                        />
                    </div>
                    : <BidFeedback
                        feedback={entity.bidAsDealerRequest?.directBid?.feedback}
                        feedbackDate={entity.bidAsDealerRequest?.directBid?.feedbackCreatedUtc}
                        bidsDueUtc={entity.bwic.bidsDueDateUtc}
                        bwicStatus={entity.bwic.status}
                        process={entity.bwic.process}
                        currentBid={entity.bidAsDealerRequest?.directBid as FeedbackCurrentBid}
                        positionId={entity.position.id}
                        bwicReferenceName={entity.bwic.referenceName}
                    />,
            className: 'data-list-cell-md-flexible cell-flexible-text cell-feedback',
        }, {
            columnKey: 'color',
            renderColumnHeaderContent: () => 'Color',
            renderColumnContent: entity => formatUtils.formatColor(entity.position.color) || constants.emptyPlaceholder,
            className: 'data-list-cell-md',
        }, collapseStickRightColumn,
        {
            columnKey: 'submit-button',
            stickRight: true,
            renderColumnHeaderContent: () => '',
            renderColumnContent: (entity, context) =>
                <TradeAction
                    trade={entity.position.trade}
                    isTradedAway={entity.position.isTradedAway}
                    positionId={entity.position.id}
                    ticker={entity.position.ticker}
                >
                    <SellerBuySideSubmitButton entity={entity} disabled={context.liveBiddingStage2Expired} />
                </TradeAction>,
            headerClassName: 'data-list-cell-sm-01 keep-while-collapsed',
            bodyClassName: 'data-list-cell-sm-01 cell-submit-button keep-while-collapsed',
        }, {
            columnKey: 'externalLinks',
            renderColumnHeaderContent: () => 'Actions',
            renderColumnContent: (entity) => (
                <>
                    <IntexLink positionId={entity.position.isinCusip} />
                    <BloombergLink identifier={entity.position.ticker} />
                    <ValitanaLink identifier={entity.position.isinCusip} />
                    <SellerBuysideHistoryButton
                        bwicReferenceName={entity.bwic.referenceName}
                        positionId={entity.position.id}
                        ticker={entity.position.ticker}
                    />
                </>
            ),
            className: 'data-list-cell cell-external-links padding-r-0 text-right',
            stickRight: true
        }
    ];

    return columnDefinitions.map(c => new ColumnBuilder(c))
}
